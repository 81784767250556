:root {
    /* dark shades of primary color*/
    --clr-primary-1: hsl(205, 86%, 17%);
    --clr-primary-2: hsl(205, 77%, 27%);
    --clr-primary-3: hsl(205, 72%, 37%);
    --clr-primary-4: hsl(205, 63%, 48%);
    /* primary/main color */
    --clr-primary-5: hsl(205, 78%, 60%);
    /* lighter shades of primary color */
    --clr-primary-6: hsl(205, 89%, 70%);
    --clr-primary-7: hsl(205, 90%, 76%);
    --clr-primary-8: hsl(205, 86%, 81%);
    --clr-primary-9: hsl(205, 90%, 88%);
    --clr-primary-10: hsl(205, 100%, 96%);
    /* darkest grey - used for headings */
    --clr-grey-1: hsl(209, 61%, 16%);
    --clr-grey-2: hsl(211, 39%, 23%);
    --clr-grey-3: hsl(209, 34%, 30%);
    --clr-grey-4: hsl(209, 28%, 39%);
    /* grey used for paragraphs */
    --clr-grey-5: hsl(210, 22%, 49%);
    --clr-grey-6: hsl(209, 23%, 60%);
    --clr-grey-7: hsl(211, 27%, 70%);
    --clr-grey-8: hsl(210, 31%, 80%);
    --clr-grey-9: hsl(212, 33%, 89%);
    --clr-grey-10: hsl(210, 36%, 96%);
    --clr-white: #fff;
    --clr-red-dark: hsl(360, 67%, 44%);
    --clr-red-light: hsl(360, 71%, 66%);
    --clr-green-dark: hsl(125, 67%, 44%);
    --clr-green-light: hsl(125, 71%, 66%);
    --clr-black: #222;
    --transition: all 0.3s linear;
    --spacing: 0.1rem;
    --radius: 0.25rem;
    --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    --max-width: 1170px;
    --fixed-width: 620px;
  }

  @import url("https://use.typekit.net/phr0mhq.css");

  p {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-bottom: 1rem;
    color: var(--clr-grey-5);
  }
  .navbar{
    background-color: black;
    position: sticky;
    overflow: visible;
    color:white;
    top: 0;
    text-decoration: none;
    z-index: 200;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2rem;
  }
.newsletter{
  display: flex;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.form{
  flex:1;
  padding: 1rem;
  border-radius: 10px;
}
.form:first-child {
  background: #eff0f09e;
}
.form, p {
  color: black;
}

.foot{
  align-items: center;
  margin-top: 2rem;
  align-self: center;
  width:100%;
}
.footer{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  margin-top: 2rem;
  height: 8rem;
  width: 100%;
}
.copyright{
  padding-left: 2rem;
}
.amplify-pagination{
  padding-top:1rem;
}
.social{
  display: block;
  padding-right: 2rem;
  text-align: center;
}
.social-links{
  padding-top: 5px;
}
.followus{
  margin-left:10px;
}
.icon{
  margin-left: 1rem;
}
  .numbers{
    /*font-family: ff-typestar-ocr-web-pro, sans-serif;*/
    font-family: "octin-stencil", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2rem;
  }
  .letters{
    /*font-family: ocr-a-std,monospace;*/
    font-family: "octin-stencil", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.2rem;
  }
  .highlightn{
    font-family: "octin-stencil", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: orange;
    font-size: 2.2rem;
  }
  .highlightl{
  
      /*font-family: ocr-a-std,monospace;*/
      font-family: "octin-stencil", sans-serif;
      font-weight: 400;
      font-style: normal;
      color: orange;
      font-size: 2.2rem;
  }

.nav-link-user{
    display: inline;
    color:#9F9893;
    position: sticky;   
    padding-right: 2rem;
    font-size: 1.3rem;
    font-style: bold;
    text-decoration: none;
    font-family: octin-college, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.nav-link-user:link {
    color:#9F9893;
    font-size: 1.3rem;
    line-height: 1.3rem;
    text-decoration: none;
  }
.nav-link-user:hover{
    color: white;
}
@media only screen and (max-width: 485px){
  .nav-links{
    display:none;
  }
  .newsletter{
    flex-direction: column;
    width:100%;
  }
  .form{
    border-radius: 0px;
  }
}
@media only screen and (min-width: 485px){
  .nav-links{
    text-align: right;
  }
}

.nav-link{
  display: inline;
  color:#9F9893;
  position: sticky;   
  padding-right: 0.8rem;
  font-size: 1.4rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: octin-college, sans-serif;
  font-weight: 400;
  font-style: normal;
}

#PostSection {
  padding-top: 1rem;
}

.nav-link:link {
  color:#9F9893;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-decoration: none;
}
.nav-link:hover{
  /*text-decoration: underline;*/
  color:white;
}

.underline{
    height: 100%;
    width: 100%;
    margin-left: auto;
	margin-right: auto;

    display: flex;
  justify-content: center;
  align-items: center;
}

.imagee{
    display:inline-flex;
}

  .post-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .post-info h4 {
    margin-bottom: 0;
  }
  .single-post button {
    background: #FFFFFF;
    border-color: transparent;
    text-transform: capitalize;
    color: var(--clr-primary-5);
    font-size: 1rem;
    cursor: pointer;
    padding: 1 rem;
    text-align: right;
  }
.interaction{
  background: #FFFFFF;
  text-align: right;
  margin-bottom:0.3rem;
}
.share{
  background-color: #FFFFFF !important;
  border-radius: 6px;
  text-transform: uppercase !important;
  color: #777 !important;
  margin:0px;
  font-size: 0.8em !important;
  border: 1px solid #cccccc !important;
  padding: 0.2em 0.9em;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%), 0 1px 2px rgb(0 0 0 / 5%);
  line-height: 20px !important;
}
.share:hover{
  background-color: #e9e9e9c2 !important;
  color:#000 !important;
}

.amplify-alert{
  text-align:center !important;
}
  .single-post {
    background: var(--clr-white);
    border-radius: var(--radius);
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: var(--light-shadow);
    transition: var(--transition);
 
  }
  .single-post:hover {
    box-shadow: var(--dark-shadow );
  }
  .single-post img {
    width: 100%;
    
    object-fit: cover;
    border-top-right-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  @media only screen and (max-width: 1500px){
    .single-post{
      width: 70%;
    }
  }

  .login-page{
    width:20%;
    display: block;
    text-align: right;
    align-items: center;
    box-shadow: var(--light-shadow);
    margin-left:auto;
    margin-right:auto;
    padding:30px;
    
  }

  .login-item{
      margin-bottom: 10px;
      margin-left:auto;
      margin-right:20%;
  }
  .registered {
    background: transparent;
    border-color: transparent;
    text-transform: capitalize;
    color: var(--clr-primary-5);
    font-size: 1rem;
    cursor: pointer;
    padding: 1 rem;
  }

  .imgDropDrag {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    margin-bottom:auto;
    padding-bottom: 2.5rem;
    flex-direction: column;
    min-height: calc(100vh - 14rem);
  }
  label {
    width: 350px;
    box-sizing: border-box;
  }
  
  .uploadPic{
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    
      
  }
  .uploadPicComp{
    text-align:center;
}

.auth{
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 14rem);
}
section{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 14rem);
}